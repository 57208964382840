import { defineDateFormat, fetchExportFile } from '@/specific/reports/export.js'
import { createVueApp } from '@/vue_components/create_vue_app'
import marketing from '@/vue_components/marketing/marketing.vue'

$document.on('rez/marketing/index', function () {
  if (!gon.application.marketing_module_enabled) return

  let clientAgeAfter,
    clientAgeBefore,
    clientBirthDate, //arr of objs
    clientCities,
    clientSexIds,
    clientGroupIds,
    clientCompanyIds,
    clientDoctorIds,
    clientAmountOfPaymentsAfter,
    clientAmountOfPaymentsBefore,
    clientHasEmail,
    clientSmsAllowed,
    clientEmailAllowed,
    clientCallsAllowed,
    clientHasNotVisited,
    clientHasUpcomingAppointments,
    clientHasNoUpcomingAppointments,
    appointmentClinicIds,
    appointmentTypeIds,
    appointmentAttractionSourceIds,
    appointmentDoctorIds,
    appointmentDoctorSpecialtyIds,
    visitDate,
    reminderDate,
    pendingReminders,
    noUpcomingReminders,
    entryDate, //obj
    entryEntryTypeIds,
    entryDiseaseIds

  const tableHeader = [
    '',
    t('full_name'),
    t('reminder'),
    t('was_born'),
    t('phone'),
    t('email'),
    t('paided'),
  ]

  const smsDispatchForm = FormFactory.build('messages/dispatch')
  const smsDispatchBtn = $('#sms_dispatch_button')
  const $ageFrom = $('#marketing_filter_age_from')
  const $ageTo = $('#marketing_filter_age_to')
  const $paymentFrom = $('#marketing_filter_sum_from')
  const $paymentTo = $('#marketing_filter_sum_to')
  const $filters = $('#new_marketing_filter')
  const $noUpcomingAppointments = $('#marketing_filter_has_no_upcoming_appointments')
  const $hasUpcomingAppointments = $('#marketing_filter_has_upcoming_appointments')
  const $periodOfHasVisits = $('#period_of_has_visits')
  const $periodOfNoPastAppoinstments = $('#period_of_no_past_appointments')
  let clientsCount = 0

  smsDispatchBtn.on('click', function (e) {
    const dispatchEnabled = gon.application.sms_dispatch_module_enabled
    const canManageDispatch = Services.security.canManageSmsDispatch
    if (dispatchEnabled && canManageDispatch) {
      const filterObject = {}

      defineParams()
      assignFilters(filterObject)

      smsDispatchForm.newItem({ clientsCount, filters: filterObject }, { useClear: true })
    }

    if (!canManageDispatch) Notificator.error(T.insufficient_access_rights)
    if (!dispatchEnabled) {
      // 7jt1ev (11)
      Utils.gIntroductionModal.setDataAndShow('fa-sms', T.introduction.sms)
    }

    e.preventDefault()
  })

  $('#submit_search').on('click', function () {
    if (
      paymentRangeInvalid() ||
      mutuallyExclusiveParametersCheck()
    ) return

    defineParams()

    return createVueApp({
      el: '#clients_table',
      name: 'ClientDashboardApp',
      render: (h) => h(marketing, {
        props: {
          clientAgeAfter,
          clientAgeBefore,
          clientBirthDate,
          clientCities,
          clientSexIds,
          clientGroupIds,
          clientCompanyIds,
          clientDoctorIds,
          clientAmountOfPaymentsAfter,
          clientAmountOfPaymentsBefore,
          clientHasEmail,
          clientSmsAllowed,
          clientEmailAllowed,
          clientCallsAllowed,
          clientHasNotVisited,
          clientHasNoUpcomingAppointments,
          clientHasUpcomingAppointments,
          appointmentClinicIds,
          appointmentTypeIds,
          appointmentAttractionSourceIds,
          appointmentDoctorIds,
          appointmentDoctorSpecialtyIds,
          visitDate,
          reminderDate,
          pendingReminders,
          noUpcomingReminders,
        entryDate,
        entryEntryTypeIds,
        entryDiseaseIds,
        tableHeader,
      smsDispatchBtn,
          setClientsCount (count) { clientsCount = count },
        },
      }),
      useChildren: true,
    })
  })

  $('.export-to-xls-btn').on('click', function () {
    defineParams()

    const data = {
      dateFormat: defineDateFormat(),
      csvComma: gon.application.csv_character,
      floatingComma: gon.localization.currency.decimal_character,
      headerRow: {
        clientFullName: tableHeader[1],
        clientBirthDate: tableHeader[3],
        clientPhone: tableHeader[4],
        clientEmail: tableHeader[5],
        clientPaidSum: tableHeader[6],
      },
      offset: 0,
      limit: -(1 << 32 - 1),
    }

    assignFilters(data)

    fetchExportFile(data, Routes.client_dashboard_path(), T.marketing)
  })

  $('#reset_btn').on('click', function () {
    $filters.find(':checkbox').prop('checked', false)
    $filters.find('input, select').val('')
    $('#entry_types_list').empty()
    $('#diseases_list').empty()
    $('.select2-selection__rendered').empty()
    $('#clients_count').text('')

    $('#clients_table').empty()

    clientsCount = 0
    smsDispatchBtn.addClass('disabled')
  })

  function defineParams () {
    clientAgeAfter = $ageFrom.val()
    clientAgeBefore = $ageTo.val()
    clientBirthDate = megaKostylForBirthdate()
    clientCities = getSelectorIdsArray('#marketing_filter_cities')
    clientSexIds = castToArray('#marketing_filter_sex_id')
    clientGroupIds = getSelectorIdsArray('#marketing_filter_client_group_ids')
    clientCompanyIds = castToArray('#marketing_filter_company_id')
    clientDoctorIds = castToArray('#marketing_filter_client_doctor_ids')
    clientAmountOfPaymentsAfter = $paymentFrom.val()
    clientAmountOfPaymentsBefore = $paymentTo.val()
    clientHasEmail = handleCheckBox('#marketing_filter_has_email')
    clientSmsAllowed = handleCheckBox('#marketing_filter_sms_allowed')
    clientEmailAllowed = handleCheckBox('#marketing_filter_email_allowed')
    clientCallsAllowed = handleCheckBox('#marketing_filter_calls_allowed')
    appointmentClinicIds = getSelectorIdsArray('#marketing_filter_clinic_ids')
    detectAppointmentDates()
    appointmentTypeIds = getSelectorIdsArray('#marketing_filter_appointment_type_ids')
    appointmentAttractionSourceIds = getSelectorIdsArray('#marketing_filter_attraction_source_ids')
    appointmentDoctorIds = castToArray('#marketing_filter_doctor_ids')
    appointmentDoctorSpecialtyIds = getSelectorIdsArray('#marketing_filter_specialty_ids')
    reminderDate = dateObj('reminder')
    pendingReminders = handleCheckBox('#marketing_filter_has_undone_reminders')
    noUpcomingReminders = handleCheckBox('#marketing_filter_has_no_upcoming_reminders')
    entryDate = dateObj('entry_date')
    entryEntryTypeIds = getSpecificIds('.entry_type_id')
    entryDiseaseIds = getSpecificIds('.disease_id')
  }

  function assignFilters (filterObject) {
    if (clientAgeAfter.length) filterObject.clientAgeAfter = clientAgeAfter
    if (clientAgeBefore.length) filterObject.clientAgeBefore = clientAgeBefore
    if (Object.values(clientBirthDate).length) filterObject.clientBirthDate = clientBirthDate
    if (clientCities.length) filterObject.clientCities = clientCities
    if (clientSexIds.length) filterObject.clientSexIds = clientSexIds
    if (clientGroupIds.length) filterObject.clientGroupIds = clientGroupIds
    if (clientCompanyIds.length) filterObject.clientCompanyIds = clientCompanyIds
    if (clientDoctorIds.length) filterObject.clientDoctorIds = clientDoctorIds
    if (clientAmountOfPaymentsAfter.length) filterObject.clientAmountOfPaymentsAfter = clientAmountOfPaymentsAfter
    if (clientAmountOfPaymentsBefore.length) filterObject.clientAmountOfPaymentsBefore = clientAmountOfPaymentsBefore
    if (clientHasEmail.length) filterObject.clientHasEmail = clientHasEmail
    if (clientSmsAllowed.length) filterObject.clientSmsAllowed = clientSmsAllowed
    if (clientEmailAllowed.length) filterObject.clientEmailAllowed = clientEmailAllowed
    if (clientCallsAllowed.length) filterObject.clientCallsAllowed = clientCallsAllowed
    if (appointmentClinicIds.length) filterObject.appointmentClinicIds = appointmentClinicIds
    if (appointmentTypeIds.length) filterObject.appointmentTypeIds = appointmentTypeIds
    if (appointmentAttractionSourceIds.length) filterObject.appointmentAttractionSourceIds = appointmentAttractionSourceIds
    if (appointmentDoctorIds.length) filterObject.appointmentDoctorIds = appointmentDoctorIds
    if (appointmentDoctorSpecialtyIds.length) filterObject.appointmentDoctorSpecialtyIds = appointmentDoctorSpecialtyIds
    if (Object.values(reminderDate).length) filterObject.reminderDate = reminderDate
    if (pendingReminders.length) filterObject.pendingReminders = pendingReminders
    if (noUpcomingReminders.length) filterObject.noUpcomingReminders = noUpcomingReminders
    if (Object.values(entryDate).length) filterObject.entryDate = entryDate
    if (entryEntryTypeIds.length) filterObject.entryEntryTypeIds = entryEntryTypeIds
    if (entryDiseaseIds.length) filterObject.entryDiseaseIds = entryDiseaseIds
    detectAppointmentDates(filterObject)
  }

  function castToArray (selector) { // для фильтров по id без мультиселекта
    return $(`${selector}`).val() ? [parseInt($(`${selector}`).val())] : []
  }

  function dateObj (selector) {
    const date = {
      start: $(`#${selector}_since`).val().split('.').reverse().join('-'),
      end: $(`#${selector}_before`).val().split('.').reverse().join('-'),
    }

    return $(`#${selector}_range`).val() ? date : Object.create(null)
  }

  function getSelectorIdsArray (selector) { // для фильтров с мультиселектом
    return $(`${selector}`).val() ? $(`${selector}`).val() : []
  }

  function getSpecificIds (selector) {
    const selectorCollection = [...$(`${selector}`)]
    const selectorIds = []
    if (selectorCollection.length) {
      selectorCollection.forEach((selectorItem) => { selectorIds.push(parseInt(selectorItem.value)) })
    }

    return selectorIds
  }

  function handleCheckBox (selector) {
    return $(`${selector}`).is(':checked') ? 'true' : ''
  }

  function detectAppointmentDates (filterObj = null) {
    visitDate = Object.create(null)
    clientHasNotVisited = Object.create(null)
    clientHasUpcomingAppointments = ''
    clientHasNoUpcomingAppointments = ''

    if ($('#appointment_range').val()) visitDate = dateObj('appointment')
    if ($periodOfHasVisits.val()) visitDate = subtractAppointmentDate('#period_of_has_visits')
    if ($hasUpcomingAppointments.is(':checked')) {
      clientHasUpcomingAppointments = moment().format('YYYY-MM-DD')
    }

    if ($periodOfNoPastAppoinstments.val()) {
      clientHasNotVisited = subtractAppointmentDate('#period_of_no_past_appointments')
    }
    if ($noUpcomingAppointments.is(':checked')) {
      clientHasNoUpcomingAppointments = moment().format('YYYY-MM-DD')
    }
    if (filterObj !== null) {
      filterObj.visitDate = visitDate
      filterObj.clientHasNoUpcomingAppointments = clientHasNoUpcomingAppointments
      filterObj.clientHasUpcomingAppointments = clientHasUpcomingAppointments
      filterObj.clientHasNotVisited = clientHasNotVisited
    }
  }

  function subtractAppointmentDate (selector) {
    return {
      start: moment().subtract(parseInt($(selector).val()), 'months').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    }
  }

  $ageTo.on('change', ageValidate)

  $ageFrom.on('change', ageValidate)

  $noUpcomingAppointments.on('change', function () {
    if ($(this).is(':checked')) {
      $hasUpcomingAppointments.parent().addClass('disabled')
      $hasUpcomingAppointments.css('pointer-events', 'none')
    } else {
      $hasUpcomingAppointments.parent().removeClass('disabled')
      $hasUpcomingAppointments.css('pointer-events', 'auto')
    }
  })

  $hasUpcomingAppointments.on('change', function () {
    if ($(this).is(':checked')) {
      $noUpcomingAppointments.parent().addClass('disabled')
      $noUpcomingAppointments.css('pointer-events', 'none')
    } else {
      $noUpcomingAppointments.parent().removeClass('disabled')
      $noUpcomingAppointments.css('pointer-events', 'auto')
    }
  })

  function ageValidate () {
    if (parseInt($ageFrom.val()) > parseInt($ageTo.val())) {
      Notificator.error(T.date_range_err)
      $(this).val('')
    }
  }

  function paymentRangeInvalid () {
    if (parseInt($paymentFrom.val()) > parseInt($paymentTo.val())) {
      Notificator.error(T.payment_range_err)

      return true
    }
  }

  function mutuallyExclusiveParametersCheck () {
    if (
      ($periodOfHasVisits.val() && $periodOfNoPastAppoinstments.val()) || (
        ($periodOfHasVisits.val() || $periodOfNoPastAppoinstments.val()) &&
        $('#appointment_range').val()
      ) || (
        $hasUpcomingAppointments.is(':checked') && $periodOfHasVisits.val()
      )
    ) {
      Notificator.error(T.mutually_exclusive_parameters)

      return true
    }
  }

  function megaKostylForBirthdate () {
    const initialDate = dateObj('birthday')
    const date = []

    if (Object.keys(initialDate).length) {
      const startYear = new Date(initialDate.start).getFullYear()
      const endYear = new Date(initialDate.end).getFullYear()

      if (startYear < endYear && startYear !== endYear) {
        date.push({
          start: regExpToDefaultYear(initialDate.start),
          end: regExpToDefaultYear(moment(initialDate.start).endOf('year').format('YYYY-DD-MM')),
        }, {
          start: regExpToDefaultYear(moment(initialDate.end).startOf('year').format('YYYY-DD-MM')),
          end: regExpToDefaultYear(initialDate.end),
        })
      } else {
        date.push({
          start: regExpToDefaultYear(initialDate.start),
          end: regExpToDefaultYear(initialDate.end),
        })
      }
    }

    return date
  }

  function regExpToDefaultYear (date) {
    return date ? date.replace(/\b\d{4}\b/, '2000') : ''
  }
})
